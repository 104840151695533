import delica from './delica-vector.svg';
import './App.css';
import { firebaseDB } from './init-firebase';

window.firebase_db = firebaseDB;

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={delica} className="App-logo" alt="logo" />
        <span>
          <div>Guess where the Delica dies!</div>
        </span>
        <hr></hr>
        <hr></hr>
        <h2>Rules:</h2>
        <p>
          Place your bets on the Final Destination of the souped up tin can
          we're all freaking nuts about!!!
        </p>
        <p>Go to the Map Tab to place your bet.</p>
        <p>You can only place one bet.</p>
        <p>The bet with the closest latitude to the Delica's final destination wins.</p>
        <p>
          Once placed, you cannot change your bet. Do not try to change it. Come
          to terms with yourself and the decisions you've made as a person.
          Venmo $5 to @William-Choulos for the chance to profit off your
          clairvoyance and start a new life with your small fortune. 
        </p>
        <p>
          You can place bets without paying, however, you will only be an honorary
          winner and will sit at the kids table forever.
        <p>
          We will update the map with
          Carol and Nathan's live location every week... or at least every so
          often when we remember to. We wish this could be our full time job but
          alas, the pay was shit and there was no dental coverage.</p>
        </p>
        <hr></hr>
        <hr></hr>
        <hr></hr>
      </header>
    </div>
  );
}

export default App;
