// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { connectDatabaseEmulator, getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAI1etdtDXxA8dLhDIO4XIcIawBnIzcFf4",
  authDomain: "delica-death-pool.firebaseapp.com",
  databaseURL: "https://delica-death-pool-default-rtdb.firebaseio.com",
  projectId: "delica-death-pool",
  storageBucket: "delica-death-pool.appspot.com",
  messagingSenderId: "116988343371",
  appId: "1:116988343371:web:d7b94e18046cc95fc489ce",
  measurementId: "G-C89Q1XZVP4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const firebaseDB = getDatabase();
if (window.location.hostname === "localhost") {
    // Point to the RTDB emulator running on localhost.
    connectDatabaseEmulator(firebaseDB, "localhost", 9000);
  } 