

import * as React from "react";
import * as ReactDom from "react-dom";
import delica from '../delica-vector.svg';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { useEffect, useRef } from "react";
import { createCustomEqual } from "fast-equals";
import { get, once, onValue, push, ref, update } from "firebase/database";

import './MapPage.css';

async function writeUserBet(name, coordinate) {
  const db = window.firebase_db;


  const nameRef = ref(db, 'users/' + name);
  const snapshot = await get(nameRef);
  if (snapshot.exists()) {
    throw new Error('User already bet!')
  }
  const betsListRef = ref(db, 'bets');
  const ret = push(betsListRef);
  const result = get(ret).then((snapshot) => {
    if (snapshot.exists()) {
      console.log(snapshot.val());
    } else {
      console.log("No data available");
    }
  })
  const newBetKey = ret.key;

  // Atomically update both bets and users
  const updates = {};
  updates['/bets/' + newBetKey] = coordinate.toJSON();

  // Use entry ID from bet to set if user created a bet
  updates['users/' + name] = newBetKey; 
  return update(ref(db), updates);
}

var previousBets = undefined;
const previousBetsRef = ref(window.firebase_db, 'bets');
onValue(previousBetsRef, (snapshot) => {
  previousBets = snapshot.val();
});

var lastKnownPosition = {
  "lat": 19.110063100227627,
  "lng": -99.76168632133836,
}

export default function MapPage() {
  const render = (status) => {
      return <h1>{status}</h1>;
  };

  const [clicks, setClicks] = React.useState([]);
  const [zoom, setZoom] = React.useState(4); // initial zoom
  const [bettorName, setBettorName] = React.useState("Your Name");
  const [center, setCenter] = React.useState({
    lat: 15.68634613800079,
    lng: -79.30213439446585,
  });

  const handleNameChange = (event) => {
    setBettorName(event.target.value);
  }

  const onClick = (e) => {
    // avoid directly mutating state
    // setClicks([...clicks, e.latLng]); This code extends the list
    setClicks([e.latLng]);
  };

  const clickToBoundary = (e) => {
    return {
      north: e.lat() + 0.25,
      south: e.lat() - 0.25,
      east: e.lng() + 25,
      west: e.lng() - 25,
    }
  };

  const onIdle = (m) => {
    setZoom(m.getZoom());
    setCenter(m.getCenter().toJSON());
  };

  const form = (
    <div
      className="map-form"
      
    >
      <h4>Where the map is centered</h4>
      <label htmlFor="zoom">Zoom</label>
      <input
        type="number"
        id="zoom"
        name="zoom"
        value={zoom}
        onChange={(event) => setZoom(Number(event.target.value))}
      />
      <br />
      <label htmlFor="lat">Latitude</label>
      <input
        type="number"
        id="lat"
        name="lat"
        value={center.lat}
        onChange={(event) =>
          setCenter({ ...center, lat: Number(event.target.value) })
        }
      />
      <br />
      <label htmlFor="lng">Longitude</label>
      <input
        type="number"
        id="lng"
        name="lng"
        value={center.lng}
        onChange={(event) =>
          setCenter({ ...center, lng: Number(event.target.value) })
        }
      />
      <hr></hr>
      <h3>Place your Bet</h3>
      <hr></hr>
      <div>
        {clicks.length === 0
          ? "Click on map to place your bet"
          : "Selected Latitude:"}
        {clicks.map((latLng, i) => (
          <pre key={i + 1000}>
            {JSON.stringify(latLng.toJSON()["lat"], null, 2)}
          </pre>
        ))}
      </div>
      <label htmlFor="name"> <b>Name (Required):</b> </label>
      <input
        type="text"
        id="bettorName"
        name="bettorName"
        value={bettorName}
        onChange={(event) => handleNameChange(event)}
      />
      <div className="map-button-container">
        <button onClick={() => setClicks([])}>Clear Bet</button>
        <button
          id="submit-button"
          disabled={!clicks.length}
          onClick={async () => {
            console.log(
              "Submitting Clicks:",
              JSON.stringify(clicks[0].toJSON())
            );
            try {
              await writeUserBet(bettorName, clicks[0]);
            } catch (error) {
              alert(error.message);
              return;
            }
            setClicks([]);
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
    

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <Wrapper
        apiKey={"AIzaSyCoBO8mG5mFZPWjhTdZUUsM4jrFJ0nY67E"}
        render={render}
      >
        <Map
          center={center}
          onClick={onClick}
          onIdle={onIdle}
          zoom={zoom}
          style={{ flexGrow: "1" }}
        >
          {clicks && clicks.map((latLng, i) => (
            <SelectionRectangle
              key={i + 2000}
              bounds={clickToBoundary(latLng)}
              fillColor={"#6ed6f0"}
              strokeColor={"#6ef07d"}
            />
          ))}
          {previousBets && window.google && Object.keys(previousBets).map((betId, index) => (
            <SelectionRectangle
              key={index + 1000}
              bounds={clickToBoundary(new window.google.maps.LatLng(previousBets[betId]))}
              betId={betId}
            />
          ))}
          {window.google && 
            <Marker key={999999}
            position={lastKnownPosition}
            zoom={zoom}
            />
          }
        </Map>
      </Wrapper>
      {/* Basic form for controlling center and zoom of map. */}
      {form}
    </div>
  );
}

const Map = ({
  onClick,
  onIdle,
  children,
  style,
  ...options
}) => {
  const ref = React.useRef(null);
  const [map, setMap] = React.useState();

  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  React.useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        window.google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

const Marker = (options) => {
  const [marker, setMarker] = React.useState();
  React.useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker) {
      marker.setOptions({
        icon: {url: delica, scaledSize: new window.google.maps.Size(50, 50)},
        ...options});
    }
  }, [marker, options]);

  return null;
};

const SelectionRectangle = (options) => {
  const [rect, setRect] = React.useState();
  const [infoWindow, setInfoWindow] = React.useState();

  React.useEffect(() => {
    if (!rect) {
      setRect(new window.google.maps.Rectangle({
        strokeColor: options.strokeColor || "#FF0000",
        strokeOpacity: options.strokeOpacity || 0.8,
        strokeWeight: options.strokeWeight || 2,
        fillColor: options.fillColor || "#FF0000",
        bounds: options.bounds,
      }));
    }

    // remove rectangle from map on unmount
    return () => {
      if (rect) {
        rect.setMap(null);
      }
    };
  }, [rect]);

  React.useEffect(() => {
    if (rect) {
      rect.setOptions(options);
      if (options.betId && !rect.infoWindow) {
        if (rect.listener !== true) {
          rect.addListener("click", async (rectMouseEvent) => {
            if (rect.infoWindow) {
              // Close the old one and create a new one
              rect.infoWindow.close();
            }
            const newInfoWindow = new window.google.maps.InfoWindow({
              position: rectMouseEvent.latLng,
            });
            // set new content
            var bettorName = undefined;
            const snapshot = await get(ref(window.firebase_db, 'users'));
            snapshot.forEach((r) => {
              if (r.val() == options.betId) {
                bettorName = r.key;
              }
            });

            newInfoWindow.setContent(
              "<b>" + bettorName + "</b>" + " bet latitute: " +
               JSON.stringify(rectMouseEvent.latLng.toJSON()['lat'].toFixed(4), null, 1)
            );
            
            // Set the ref to the new one and make it visible to the user
            rect.infoWindow = newInfoWindow;
            newInfoWindow.open(rect);
          });
          rect.listener = true;
        }
      } else {
        // if (rect.infoWindow) {
        //   rect.infoWindow.close();
        // }
      }
    }
  }, [rect, options, infoWindow]);

  return null;
};

const deepCompareEqualsForMaps = createCustomEqual(
  (deepEqual) => (a, b) => {
    if (
      a instanceof window.google.maps.LatLng ||
      b instanceof window.google.maps.LatLng
    ) {
      return new window.google.maps.LatLng(a).equals(new window.google.maps.LatLng(b));
    }

    // TODO extend to other types

    // use fast-equals for other objects
    return deepEqual(a, b);
  }
);

function useDeepCompareMemoize(value) {
  const ref = React.useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

function useDeepCompareEffectForMaps(
  callback,
  dependencies
) {
  React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}


// I believe this was used when the map was being added to the root
// window.addEventListener("DOMContentLoaded", () => {
//   ReactDom.render(<App />, document.getElementById("root"));
// });