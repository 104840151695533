import { Outlet } from "react-router-dom";
import TopNav from "../components/TopNav";

export default function Root() {
    return (
      <>
        <TopNav />
        <div id="details">
          <Outlet />
        </div>
      </>
    );
}