import delica from '../delica-vector.svg';
import { Link } from 'react-router-dom';

export default function TopNav() {
  return (
    <nav className="navbar navbar-expand-sm navbar-dark bg-dark">
      <Link className="navbar-brand nav-item" style={{ color: "#FFFF" }} to="/">
        <img src={delica} className="nav-logo" alt="logo" />
        Delica Death Pool
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <div className="navbar-nav mr-auto">
          <Link className="nav-link nav-item active" to="/map">
            Map
          </Link>
        </div>
      </div>
    </nav>
  );
}